import React, { useEffect, useState, createContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../assets/img/logo_black.png";
import figure from "../assets/img/mona_figure.png";
import LoadingOverlay from "react-loading-overlay";
import Modal from "../Components/Modal";

export const RootContext = createContext();
const Provider = RootContext.Provider;

const Mona = () => {
  const [show, setShow] = useState(true);
  const [isActive, setIsActive] = useState(true);

  const closeModalHandler = () => {
    setShow(false);
    
    sessionStorage.setItem("survey-flag", "true");
  };

  useEffect(() => {
    document.title = process.env.REACT_APP_TITLE;
    setIsActive(false);
    window.onbeforeunload = null;

    const surveyFlag = sessionStorage.getItem("survey-flag");
    if (surveyFlag === null) {
      setShow(true);
    } else {
      setShow(false);
    }

    if(window.performance
      .getEntriesByType('navigation')
      .map((nav) => nav.type)
      .includes('reload')){
      sessionStorage.removeItem("widget-flag");
    }
  }, []);
  return (
    <LoadingOverlay active={isActive} spinner text="Loading Your MONA">
      <Provider>
        {show ? <div className="back-shed"></div> : null}

        <Modal className="modal" show={show} close={closeModalHandler}></Modal>

        <Container>
          <Row>
            <Col>
              <img src={logo} style={styles.logo} id="logo" />
            </Col>
          </Row>
          <Row>
            <Col>
              <h1 style={styles.title} className="title">
                Hi, I'm MONA.
              </h1>
              <h1 style={styles.title} className="title">
                Ask me anything!
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <img src={figure} style={styles.imageFigure} id="figure" />
            </Col>
          </Row>
          <Row>
            <Col>
              <a
                href="tel:1500715"
                className="btn btn-lg"
                style={styles.buttonBlack}
              >
                Call
              </a>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link
                to="/chat"
                className="btn btn-lg"
                style={styles.buttonWhite}
              >
                Chat
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link
                to="/video-call"
                className="btn btn-lg"
                style={styles.buttonWhite}
              >
                Video Call
              </Link>
            </Col>
          </Row>

          <Row>
            <Col>
              <h6 style={styles.footerHeader} className="footer">
                © MODENA 2022 | All rights reserved
              </h6>
            </Col>
          </Row>

          <Row>
            <Col>
              <h6 style={styles.footerTitle} className="footer">
                Operational Hours
              </h6>
              <h6 style={styles.footerSubTitle} className="footer">
                Monday - Friday 9.00 - 17.00
              </h6>
              <h6 style={styles.footerSubTitle} className="footer">
                Saturday 9.00 - 17.00
              </h6>
              <h6 style={styles.footerEnd} className="footer">
                Sunday and public holidays off.
              </h6>
            </Col>
          </Row>
        </Container>
      </Provider>
    </LoadingOverlay>
  );
};
const styles = {
  title: {
    fontFamily: "Inter-SemiBold",
    textAlign: "center",
    margin: "5px 0px",
  },
  logo: {
    marginLeft: "72%",
    marginTop: "30px",
    marginBottom: "40px",
    width: "22%",
  },
  imageFigure: {
    width: "70%",
    marginLeft: "15%",
    marginTop: "30px",
    marginRight: "15%",
    marginBottom: "10px",
  },
  buttonBlack: {
    fontFamily: "Inter-Regular",
    backgroundColor: "#262626",
    border: "1px solid #262626",
    boxSizing: "border-box",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    fontWeight: "500",
    textAlign: "center",
    width: "70%",
    padding: "15px",
    fontSize: "15px",
    margin: "5px 15%",
    color: "white",
  },
  buttonWhite: {
    fontFamily: "Inter-Regular",
    backgroundColor: "#F2F2F2",
    border: "1px solid #262626",
    boxSizing: "border-box",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    fontWeight: "500",
    textAlign: "center",
    width: "70%",
    padding: "14px",
    fontSize: "14px",
    margin: "5px 15%",
    color: "black",
  },
  footerHeader: {
    fontFamily: "Inter-Light",
    fontSize: "12px",
    width: "50%",
    margin: "50px 25% 20px 25%",
    textAlign: "center",
  },
  footerTitle: {
    fontFamily: "Inter-Light",
    fontWeight: "bold",
    fontSize: "12px",
    width: "50%",
    margin: "20px 25% 20px 25%",
    textAlign: "center",
  },
  footerSubTitle: {
    fontFamily: "Inter-Light",
    fontSize: "12px",
    width: "50%",
    margin: "10px 25% 10px 25%",
    textAlign: "center",
  },
  footerEnd: {
    fontFamily: "Inter-Light",
    fontSize: "12px",
    width: "50%",
    margin: "30px 25% 45px 25%",
    textAlign: "center",
  },
};
export default Mona;
