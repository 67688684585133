import React from "react";
import './App.css';
import Mona from './Components/Mona';
import Chat from './Components/Chat';
import VideoCall from './Components/VideoCall';
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
  return (
    <Router>
        <Switch>
          <Route path="/" component={Mona} exact />
          <Route path="/chat" component={Chat} exact />
          <Route path="/video-call" component={VideoCall} exact />
        </Switch>
    </Router>
  );
}

export default App;
