import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import axios from "axios";
import load from "../assets/img/load.gif";

class VideoCall extends Component {
  componentDidMount() {
    document.title = process.env.REACT_APP_TITLE;
    var domain_call = process.env.REACT_APP_CALL_DOMAIN;
    var app_id_call = process.env.REACT_APP_CALL_ID;
    var app_id_widget = process.env.REACT_APP_WIDGET_ID;
    var app_secret_id = process.env.REACT_APP_SECRET_ID;
    var channel_id_widget = process.env.REACT_APP_WIDGET_CHANNEL_ID;
    var name = process.env.REACT_APP_USER_NAME;
    var email = process.env.REACT_APP_USER_EMAIL;
    var agent_name = process.env.REACT_APP_AGENT_NAME;
    var agent_email = process.env.REACT_APP_AGENT_EMAIL;
    var url_api_qiscus = process.env.REACT_APP_URL_API_QISCUS;

    const addStyle = function (styleString) {
      const style = document.createElement("style");
      style.setAttribute("id", "qiscus-css-manipulator");
      style.textContent = styleString;
      document.head.append(style);
    };

    const initMeet = async function () {
      var roomId;

      if (sessionStorage.getItem("room_id") === null) {
        var tempRoomId = Math.random().toString(36).substring(2, 10);
        sessionStorage.setItem("room_id", tempRoomId);
        roomId = tempRoomId;
      } else {
        roomId = sessionStorage.getItem("room_id");
      }

      localStorage.setItem(
        "qismo-widget",
        JSON.stringify({
          unique_id: email,
          display_name: name,
        })
      );

      var domain = domain_call;
      var appId = app_id_call;
      var data = {
        avatar: "",
        name: name,
        email: email,
        appId: appId,
        room: roomId,
        moderator: false,
      };
      var core = new window.QiscusMeetCoreAPI();
      var jwt = await core.getJWT(data);
      var options = {
        roomName: appId + "/" + roomId,
        parentNode: document.querySelector("#meet"),
        configOverwrite: {
          startWithAudioMuted: true,
          startWithVideoMuted: true,
          hideConferenceSubject: true,
          enableFeedbackPage: true,
        },
        interfaceConfigOverwrite: {
          TOOLBAR_BUTTONS: [
            "microphone",
            "camera",
            "hangup",
            "toggle-camera",
            "filmstrip",
          ],
          VIDEO_LAYOUT_FIT: "both",
          DEFAULT_REMOTE_DISPLAY_NAME: "Guest",
          DISPLAY_WELCOME_PAGE_CONTENT: false,
          APP_NAME: "Qiscus",
          NATIVE_APP_NAME: "Qiscus",
          PROVIDER_NAME: "Qiscus",
          DEFAULT_LOGO_URL:
            "https://modena.com/dev/public/frontend/images/logo-modena-putih.png",
          LOGO_WATERMARK_LINK:
            "https://modena.com/dev/public/frontend/images/logo-modena-putih.png",
        },
        userInfo: {
          email: agent_email,
          displayName: agent_name,
        },
        jwt: jwt.token,
      };
      var api = new window.QiscusMeetExternalAPI(domain, options);

      var timer = setTimeout(function () {
        document.getElementById("meet").style.display = "none";
        document.getElementById("loading-room").style.display = "none";
        document.getElementById("loading-1-minute-room").style.display =
          "block";
        document.getElementById("hangup-room").style.display = "none";

        timer = setTimeout(function () {
          document.getElementById("meet").style.display = "none";
          document.getElementById("loading-room").style.display = "none";
          document.getElementById("loading-1-minute-room").style.display =
            "none";
          document.getElementById("hangup-room").style.display = "block";

          timer = setTimeout(function () {
            api.meet.executeCommand("hangup");
          }, 5000);
        }, 120000);
      }, 60000);

      api.meet.on("participantJoined", (data) => {
        clearTimeout(timer);

        document.getElementById("meet").style.display = "block";
        document.getElementById("loading-room").style.display = "none";
        document.getElementById("loading-1-minute-room").style.display = "none";
        document.getElementById("hangup-room").style.display = "none";
      });

      api.meet.on("videoConferenceLeft", (data) => {
        window.onbeforeunload = null;

        document.location.href =
          window.location.origin + window.location.pathname;
      });

      window.onhashchange = () => {
        clearTimeout(timer);
      };

      return jwt.url;
    };

    const sendInvitation = function (url) {
      var QismoApp = new window.Qismo(app_id_widget, {
        options: {
          channel_id: channel_id_widget,
        },
        onRoomChanged(data) {
          sessionStorage.setItem("qiscus_room_id", data.id);

          url = url + "#userInfo.displayName=%22agent%22";
          sendMessage(url);
        },
        onNewMessages(data) {
          var json_data = JSON.parse(JSON.stringify(data[0]));
          if (
            json_data.type === "system_event" &&
            / joined this conversation/.test(json_data.message)
          ) {
            if (/ has /.test(json_data.message)) {
              url =
                url +
                "#userInfo.displayName=%22" +
                encodeURI(
                  json_data.message.substr(
                    0,
                    json_data.message.indexOf(" has joined this conversation")
                  )
                ) +
                "%22";
            } else {
              url =
                url +
                "#userInfo.displayName=%22" +
                encodeURI(
                  json_data.message.substr(
                    0,
                    json_data.message.indexOf(" joined this conversation")
                  )
                ) +
                "%22";
            }

            sendMessage(url);
          }
        },
      });
    };

    initMeet().then((url) => {
      sendInvitation(url);
      document.getElementById("qismo-widget").style.display = "none";
    });

    const sendMessage = function (url) {
      const headers = {
        "Content-Type": "application/json",
        "QISCUS-SDK-APP-ID": app_id_widget,
        "QISCUS-SDK-SECRET": app_secret_id,
      };

      const body = {
        user_id:
          localStorage.getItem("qismo-widget") !== null
            ? JSON.parse(localStorage.getItem("qismo-widget")).unique_id
            : "",
        room_id: sessionStorage.getItem("qiscus_room_id"),
        type: "buttons",
        payload: {
          text: "Hi MODENA, I want to invite you to this call",
          buttons: [
            {
              label: "Join Call",
              type: "link",
              payload: {
                url: url,
                method: "get",
                payload: null,
              },
            },
          ],
        },
      };
      axios.post(url_api_qiscus, body, { headers }).then((response) => {});
    };

    var hs = document.getElementsByTagName("style");
    for (var i = 0, max = hs.length; i < max; i++) {
      if (hs[i].id === "qiscus-css-manipulator") {
        hs[i].parentNode.removeChild(hs[i]);
        break;
      }
    }

    var hl = document.getElementsByTagName("link");
    for (var i = 0, max = hl.length; i < max; i++) {
      if (
        hl[i].rel === "stylesheet" &&
        hl[i].href === process.env.REACT_APP_WIDGET_RESTYLE_CSS
      ) {
        hl[i].parentNode.removeChild(hl[i]);
        break;
      }
    }

    addStyle(`
            #qcw-iframe, #qcw-login-form-iframe {
                display: none !important;
                width: 0 !important;
                height: 0 !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
            
            .qcw-trigger-btn {
                display: none !important;
            }

            #qcw-welcome-iframe {
                display: none !important;
            }
            
            @media(max-width: 600px){
                #qcw-iframe, #qcw-login-form-iframe {
                    display: none !important;
                    width: 0 !important;
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                }
            }
        `);

    window.onbeforeunload = (event) => {
      const e = event || window.event;

      e.preventDefault();
      if (e) {
        e.returnValue = "";
      }
      return "";
    };
  }
  render() {
    return (
      <div id="container">
        <Container style={styles.container}>
          <div id="loading-room" style={styles.loadingRoom}>
            <h4 style={styles.formTitle}>MONA Video Call</h4>
            <div style={styles.formSubtitle}>
              Welcome! I’m MONA your personal assistance, you are on the que to
              talk with us.
            </div>
            <div style={styles.formSubtitle}>
              <img src={load} alt="loading..." style={styles.loading} />
            </div>
          </div>

          <div id="loading-1-minute-room" style={styles.loadingRoom1Minute}>
            <h4 style={styles.formTitle}>MONA Video Call</h4>
            <div style={styles.formSubtitle}>
              Please be patient with us, we are still connecting you with our
              team.
            </div>
            <div style={styles.formSubtitle}>
              <img src={load} alt="loading..." style={styles.loading} />
            </div>
          </div>

          <div id="hangup-room" style={styles.hangupRoom}>
            <h4 style={styles.formTitle}>MONA Video Call</h4>
            <div style={styles.formSubtitle}>
              We are sorry all team seem busy at the moment, could you please
              contact us a few mins later.
            </div>
          </div>
        </Container>

        <div id="meet" style={styles.meet}></div>
      </div>
    );
  }
}

const styles = {
  container: {
    marginTop: "10%",
    marginBottom: "10%",
    backgroundColor: "white",
  },
  formTitle: {
    fontWeight: "bolder",
    marginTop: "50%",
    color: "orange",
    width: "100%",
    textAlign: "center",
    fontFamily: "Inter-SemiBold",
  },
  formSubtitle: {
    fontSize: 12,
    marginTop: 6,
    width: "100%",
    textAlign: "center",
    fontFamily: "Inter-Light",
  },
  meet: {
    position: "absolute",
    bottom: "0",
    right: "0",
    width: "100%",
    height: "100%",
    overflow: "none",
    display: "none",
  },
  loadingRoom: {
    display: "block",
  },
  loadingRoom1Minute: {
    display: "none",
  },
  hangupRoom: {
    display: "none",
  },
  loading: {
    width: "7%",
    marginTop: "5%",
  },
};

export default withRouter(VideoCall);
