import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";

class Chat extends Component {
  componentDidMount() {
    window.onbeforeunload = null;

    const addStyle = function(styleString) {
      const style = document.createElement("style");
      style.setAttribute("id", "qiscus-css-manipulator");
      style.textContent = styleString;
      document.head.append(style);
    };

    if (localStorage.getItem("qismo-widget") !== null) {
      if (
        JSON.parse(localStorage.getItem("qismo-widget")).unique_id ===
        process.env.REACT_APP_USER_EMAIL
      ) {
        addStyle(`
            #qcw-iframe, #qcw-login-form-iframe {
                width: 0 !important;
                height: 0 !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
        `);
        localStorage.removeItem("qismo-widget");
        window.location.reload();
      }
    }

    document.title = process.env.REACT_APP_TITLE;
    var app_id_widget = process.env.REACT_APP_WIDGET_ID;
    var channel_id_widget = process.env.REACT_APP_WIDGET_CHANNEL_ID;

    const initWidget = function() {
      var QismoApp = new window.Qismo(app_id_widget, {
        options: {
          channel_id: channel_id_widget,
        },
      });
    };

    var hs = document.getElementsByTagName("style");
    for (var i = 0, max = hs.length; i < max; i++) {
      if (hs[i].id === "qiscus-css-manipulator") {
        hs[i].parentNode.removeChild(hs[i]);
        break;
      }
    }

    var hl = document.getElementsByTagName("link");
    for (var i = 0, max = hl.length; i < max; i++) {
      if (
        hl[i].rel === "stylesheet" &&
        hl[i].href === process.env.REACT_APP_WIDGET_RESTYLE_CSS
      ) {
        hl[i].parentNode.removeChild(hl[i]);
        break;
      }
    }

    const loadingDone = function() {
      var check_flag =
        sessionStorage.getItem("widget-flag") == null ? true : false;
      var check_compt =
        document.querySelector(".qcw-trigger-btn") != null ? true : false;

      if (check_compt) {
        if (check_flag) {
          document.querySelector(".qcw-trigger-btn").click();
        } else {
          document.querySelector(".qcw-trigger-btn").click();
          document.querySelector(".qcw-trigger-btn").click();
          sessionStorage.removeItem("widget-flag");
        }

        window.onhashchange = function() {
          sessionStorage.setItem("widget-flag", true);
        };
      } else {
        sessionStorage.removeItem("widget-flag");
        window.setTimeout(loadingDone, 100);
      }
    };

    initWidget();

    addStyle(`
        #qcw-iframe, #qcw-login-form-iframe {
            width: 30% !important;
            height: 100% !important;
            margin-left: 35% !important;
            margin-right: 35% !important;
            right: unset !important;
            bottom: unset !important;
        }
        
        .qcw-trigger-btn {
            display: none !important;
        }

        #qcw-welcome-iframe {
            display: none !important;
        }
        
        @media(max-width: 600px){
            #qcw-iframe, #qcw-login-form-iframe {
                width: 100% !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
        }
    `);

    loadingDone();
  }
  render() {
    return <Container></Container>
  }
}

const styles = {
  container: {
    marginTop: "10%",
    marginBottom: "10%",
    backgroundColor: "white",
  },
  formSubtitle: {
    fontSize: 12,
    marginTop: 6,
    width: "100%",
    textAlign: "center",
    fontFamily: "Inter-Light",
  },
  loadingRoom: {
    display: "block",
  },
  loading: {
    width: "3.5%",
    marginTop: "20%",
  },
};

export default withRouter(Chat);
