import React from "react";
import "../assets/css/modal.css";
import figure from "../assets/img/survey_figure.png";
import close from "../assets/img/cross-circle.png";

const Modal = (props) => {
  return (
    <div>
      <div
        className="modal-wrapper"
        style={{
          display: props.show ? "block" : "none",
        }}
      >
        <div className="modal-header">
          <span className="close-modal-btn" onClick={props.close}>
            <img
              src={close}
              style={styles.modalClose}
              className="modal-close"
            />
          </span>
        </div>
        <div className="modal-body">
          <img src={figure} className="modal-figure" />
          <p style={styles.modalText} className="modal-text">
            Take our survey &
          </p>
          <p style={styles.modalText2} className="modal-text">
            get exciting discounts!
          </p>
        </div>
        <div className="modal-footer">
          <a
            href={process.env.REACT_APP_SURVEY_LINK}
            target="_blank"
            className="btn btn-lg modal-button"
            style={styles.modalButton}
            onClick={props.close}
          >
            Take Survey
          </a>
        </div>
      </div>
    </div>
  );
};

const styles = {
  modalButton: {
    fontFamily: "Inter-Regular",
    backgroundColor: "#262626",
    border: "1px solid #262626",
    boxSizing: "border-box",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    fontWeight: "500",
    textAlign: "center",
    width: "80%",
    padding: "12px",
    fontSize: "13px",
    margin: "5px 10%",
    color: "white",
  },
  modalHeader: {
    fontFamily: "Inter-SemiBold",
    fontSize: "17px",
    width: "100%",
  },
  modalText: {
    fontFamily: "Inter-SemiBold",
    fontSize: "14px",
    width: "100%",
    textAlign: "center",
  },
  modalText2: {
    fontFamily: "Inter-SemiBold",
    fontSize: "14px",
    width: "100%",
    textAlign: "center",
    marginTop: "-10px",
  },
  modalClose: {
    width: "30%",
  },
};

export default Modal;
